
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import ExportOptions from "@/components/export-options.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import purchaseService from '@/services/purchase-services'
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";

@Component({
    components: {
        Header,
        Sidebar,
        Loader,
        ExportOptions,
        FilterComponent,
        PopUp,

    },
})
export default class TransactionConfiguration extends Vue {
    loading = true;
    public selected_items: any = [];
    public currentPage = 1;
    public payload: any = {
        currency: "",
        rate: null
    }
    error:any="";
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    public fields = [
        { key: "currency", sortable: false, label: "Currency" },
        { key: "rate", sortable: false, },
        { key: "updated_at", sortable: false,formatter: (value: any) => {
        if (value) {
          const dateTime = new Date(value);
          const formattedDate = dateTime
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, "-"); // Format as dd-mm-yyyy
          const formattedTime = dateTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }); // Format as HH:MM:SS
          return `${formattedDate} / ${formattedTime}`;
        }
        return "";
      }, },
        { key: "action" }

    ];
    get rows() {
        return this.selected_items.length;
    }
    currency_id = null
    update(data: any) {
        this.error=""
        this.currency_id = data.id
        this.payload.currency = data.currency
        this.payload.rate = data.rate
        this.$root.$emit("bv::toggle::collapse", "sidebar-variant-transaction");
    }
    save() {
        this.error = ""
    const error_list = utils.getEmptyKeys(this.payload, ['currency','rate'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else {
        if (this.currency_id === null) {
            purchaseService.createCurrencyRate(this.payload).then((response) => {
                if (response) {
                    this.showModal("Successfully Created", [], "success")
                    this.retrieve()
                    this.clear()
                    this.currency_id=null;
                    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-transaction");
                }

            }).catch((err) => {
                console.log(err)
                this.showModal("Not Created", [], "error")
            })
        }
        else if (this.currency_id !== null) {
            purchaseService.updateCurrencyRate(this.currency_id, this.payload).then((response) => {
                if (response) {
                    this.showModal("Successfully Updated", [], "success")
                    this.retrieve()
                    this.clear()
                    this.currency_id=null;
                    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-transaction");
                }
            })
                .catch((err) => {
                    console.log(err)
                    this.showModal("Not Updated", [], "error")
                })
        }
    }
    }
    mounted() {
        this.retrieve()
    }
    retrieve() {
        this.loading = true;
        purchaseService.getAllCurencies().then((resp) => {
            this.selected_items = resp.data
            this.loading = false;
        }).catch((e) => {
          console.log(e);
        });
    }
    clear() {
        this.payload = {
            currency: "",
            rate: null
        }
        this.error=""
    }

}
